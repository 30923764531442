<div class="admin-view admin-modul dv-table">
    <div class="row">
        <div class="col-md-8 col-md-offset-2">
            <div class="view-title margin-top-10">
                <h1 translate>ADMIN</h1>
            </div>
            <!-- Application Properties: Tabelle -->
            <div class="row margin-top-10">
                <div class="col-md-12 ebegu-table table-responsive">
                    <mat-table [dataSource]="displayedCollection" matSort>
                        <!--Name-->
                        <ng-container matColumnDef="name">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header="name"
                            >
                                <span translate>PROPERTY_NAME</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let property">
                                <span [title]="property.name"
                                    >{{ property.name | translate }}
                                    <dv-tooltip
                                        [text]="property.erklaerung"
                                    ></dv-tooltip>
                                </span>
                            </mat-cell>
                        </ng-container>

                        <!--Value-->
                        <ng-container matColumnDef="value">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header="value"
                            >
                                <span translate>PROPERTY_VALUE</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let property">
                                @switch (type(property.name)) {
                                    @case (Date) {
                                        <span [title]="property.value">{{
                                            property.value
                                                | stringSqlDateToDisplayDate
                                        }}</span>
                                    }
                                    @default {
                                        <span [title]="property.value">{{
                                            property.value | translate
                                        }}</span>
                                    }
                                }
                            </mat-cell>
                        </ng-container>

                        <!--Timestamp Erstellt-->
                        <ng-container matColumnDef="timestampErstellt">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header="timestampErstellt"
                            >
                                <span translate>TIMEERSTELLT</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let property">
                                <span [title]="property.timestampErstellt">{{
                                    property.timestampErstellt.format(
                                        'DD.MM.YYYY'
                                    )
                                }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="filter">
                            <mat-header-cell *matHeaderCellDef="let column">
                                <mat-form-field
                                    floatLabel="never"
                                    class="dv-table-mat-form-field"
                                >
                                    <input
                                        matInput
                                        type="text"
                                        class="einstellung-filter"
                                        (keyup)="doFilter($event.target.value)"
                                        placeholder="{{
                                            'SEARCH_FOR_NAME' | translate
                                        }}"
                                    />
                                </mat-form-field>
                            </mat-header-cell>
                        </ng-container>

                        <mat-header-row
                            *matHeaderRowDef="displayedColumns"
                        ></mat-header-row>
                        <mat-header-row
                            *matHeaderRowDef="filterColumns"
                        ></mat-header-row>
                        <mat-row
                            *matRowDef="let row; columns: displayedColumns"
                            (keydown)="editRow(row)"
                            (click)="editRow(row)"
                        ></mat-row>
                    </mat-table>
                </div>
            </div>
            <!-- Application Properties Detail -->
            <div *ngIf="!isReadonly()">
                <form
                    (ngSubmit)="form.valid && submit()"
                    name="form"
                    class=""
                    novalidate
                    *ngIf="configurable()"
                    unsaved-warning-form
                >
                    <div class="row margin-top-40">
                        <div class="col-md-12 form-group">
                            <div class="row">
                                <div class="col-md-2">
                                    <span class="md-no-float">{{
                                        'PROPERTY_NAME' | translate
                                    }}</span>
                                </div>
                                <div class="col-md-6">
                                    <span>{{
                                        configurable().key | translate
                                    }}</span>
                                </div>
                                <div class="col-md-4">
                                    <dv-edit-einstellung
                                        [readOnly]="false"
                                        [einstellung]="configurable()"
                                        (einstellungChange)="
                                            updateApplicationProperty($event)
                                        "
                                    ></dv-edit-einstellung>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <dv-loading-button
                                type="submit"
                                [buttonClass]="'color-success'"
                            >
                                <span>{{ 'SAVE' | translate }}</span>
                            </dv-loading-button>
                        </div>
                        <div class="col-md-4">
                            <dv-loading-button
                                type="submit"
                                (buttonClick)="
                                    resetForm();
                                    form.form.markAsPristine();
                                    form.form.markAsUntouched()
                                "
                                [buttonClass]="'cancel-button'"
                            >
                                <span>{{ 'CANCEL' | translate }}</span>
                            </dv-loading-button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Suchindex -->
            <div *ngIf="!isReadonly() && isSuperadmin()">
                <div class="view-title margin-top-40">
                    <h1>{{ 'CREATE_SEARCHINDEX' | translate }}</h1>
                </div>
                <div class="row margin-top-10">
                    <div class="col-md-12 form-group">
                        <dv-loading-button
                            [buttonDisabled]="reindexInProgress"
                            (buttonClick)="startReindex()"
                        >
                            <span>{{ 'CREATE_SEARCHINDEX' | translate }}</span>
                        </dv-loading-button>
                    </div>
                </div>
            </div>

            <!-- Alle Faelle Sicht erneu bauen -->
            <div *ngIf="!isReadonly() && isSuperadmin()">
                <div class="view-title margin-top-40">
                    <h1>{{ 'RECREATE_ALLE_FAELLE_VIEW' | translate }}</h1>
                </div>
                <div class="row margin-top-10">
                    <div class="col-md-12 form-group">
                        <dv-loading-button
                            [buttonDisabled]="recreateAlleFaelleInProgress"
                            (buttonClick)="startRecreateAlleFaelleView()"
                        >
                            <span>{{
                                'RECREATE_ALLE_FAELLE_VIEW' | translate
                            }}</span>
                        </dv-loading-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
